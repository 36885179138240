export default [
  {
    title: 'Gestão de Leads',
    description:
      'Registre potenciais clientes e faça o acompanhamento das datas de contatos para visualizar através de gráficos o seu funil de vendas.',
    img: 'https://i.imgur.com/30mGbcB.jpeg',
    downloadLink: 'https://bit.ly/planilha-gestao-leads-dyn0001',
    id: 1
  },
  {
    title: 'Gestão Financeira',
    description:
      'Cadastre metas de economia e faça o controle de todos os gastos e despesas pessoais nessa planilha interativa, com gráficos para acompanhamento do seu orçamento mensal.',
    img: 'https://i.imgur.com/MoqhWEP.jpeg',
    downloadLink: 'https://bit.ly/planilha-gestao-financeira-dyn0002',
    id: 2
  }
];
